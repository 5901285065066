import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AUTH_USER_TOKEN_KEY } from '../Constants';
import { notification } from 'antd';
import { RootState } from 'store/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import {
  setActiveProjectId,
  clearProjects,
} from 'store/projects/projectsActions';

type DispatchType = ThunkDispatch<RootState, void, Action>;

interface IProps {
  clearActiveProjectId: () => void;
  clearProjects: () => void;
}

const PageLogout = (props: IProps) => {
  const history = useHistory();

  useEffect(() => {
    Auth.signOut();
    props.clearActiveProjectId();
    props.clearProjects();
    localStorage.removeItem('USER_ID');
    localStorage.removeItem(AUTH_USER_TOKEN_KEY);
    localStorage.removeItem('USER_EMAIL');
    localStorage.removeItem('USER_ROLE');
    localStorage.removeItem('USER_SUBDOMAIN');
    localStorage.removeItem('SUB_TOKEN_NAME');
    localStorage.removeItem('SUB_REFRESH_TOKEN_NAME');
    localStorage.removeItem('ACTIVE_SUB_ID_NAME');
    localStorage.removeItem('pages');
    localStorage.removeItem('userAccess');
    localStorage.removeItem('activeAccount');
  });

  useEffect(() => {
    notification.success({
      message: 'Successful log out!',
      placement: 'topRight',
      duration: 1.5,
      onClose: () => {
        history.replace({ pathname: '/console/login' });
      },
    });
  }, []);

  return <Layout></Layout>;
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
  clearActiveProjectId: () => dispatch(setActiveProjectId(null)),
  clearProjects: () => dispatch(clearProjects()),
});

export default connect(null, mapDispatchToProps)(PageLogout);
