import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { RootState } from 'store/rootReducer';
import { IGenerationTemplate } from 'types';
import { graphQlCall } from 'graphql/utils';
import clsx from 'clsx';
import queries from 'graphql/queries';
import SideMenu from 'Components/SideMenu/SideMenu';
import GenerationTemplateCard from 'Components/GenerationTemplate/GenerationTemplateCard/GenerationTemplateCard';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import burgerSvg from 'Assets/icons/burger.svg';
import Pagination from 'UILib/Pagination/Pagination';

import styles from './AutoApp.module.scss';

const sortOptions = [
  {
    label: 'Last Update',
    value: 'updatedAt',
    sortAsc: false,
  },
  {
    label: 'File Name',
    value: 'name',
    sortAsc: true,
  },
];

const LIMIT = 20;

interface IProps {
  autoApps?: IAutoApp[];
}

interface IParams {
  appId: string;
}

const AutoApp = ({ autoApps }: IProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [appData, setAppData] = useState<IAutoApp>();

  const [generationTemplates, setGenerationTemplates] = useState<
    IGenerationTemplate[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>();

  const history = useHistory();

  const { appId } = useParams<IParams>();

  useEffect(() => {
    if (appData?._id) {
      graphQlCall({
        queryTemplateObject:
          queries.GET_GENERATION_TEMPLATES_QUERY_WITH_PAGINATION,
        headerType: 'USER-AUTH',
        values: {
          type: appData?._id,
          skip: (currentPage - 1) * LIMIT,
          limit: LIMIT,
          search: searchTerm,
          sortBy,
          sortAsc: sortOptions.find((item) => item.value === sortBy)?.sortAsc,
        },
      }).then((item) => {
        setGenerationTemplates(item.templates || []);
        setTotalCount(item.total);
      });
    }
  }, [searchTerm, sortBy, currentPage, appData]);

  useEffect(() => {
    if (appId && !!autoApps?.length) {
      setAppData(autoApps.find((item) => item._id === appId));
    }
  }, [appId, autoApps]);

  const handleDelete = (id: string) => {
    setGenerationTemplates(
      generationTemplates.filter((item) => item._id !== id)
    );
  };

  const handleTemplateClick = (senderId: String) => {
    history.push(
      `/console/generationTemplate/${senderId}/edit?app=${appData?._id}&editor=true`
    );
  };

  const handleCreateNewFile = () => {
    history.push(
      `/console/file/${appData?._id}/${appData?.template?._id}/create`
    );
  };

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.pageNavigationWrapper, {
          [styles.moveLeft]: !isSidebarOpen,
        })}
      >
        <SideMenu />
        <div
          className={styles.sidebarToggleBtnBlock}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img src={burgerSvg} alt="burger svg" />
        </div>
      </div>
      <main
        className={clsx(
          styles.containerContent,
          isSidebarOpen ? styles.contentRight : styles.content
        )}
      >
        <div className={styles.overflowContainer}>
          <DashboardHeader
            handleAddNewItem={handleCreateNewFile}
            buttonText="New Project"
            sortOptions={sortOptions}
            sortBy={sortBy}
            handleSort={(value: string) => setSortBy(value)}
            handleSearch={setSearchTerm}
            title="Your Projects"
            total={`${totalCount} Projects`}
          />
          <div className={styles.generationTemplateElements}>
            {generationTemplates.map((item, index) => (
              <GenerationTemplateCard
                key={item._id}
                item={item}
                onClick={handleTemplateClick}
                onDelete={() => handleDelete(item._id)}
              />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalCount / LIMIT)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autoApps: state.autosaas.autoApps,
});

export default connect(mapStateToProps)(AutoApp);
