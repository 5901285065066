import clsx from 'clsx';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import {
  updateItem,
  updateItemStyle,
} from 'Pages/PageGenerationEditor/Draggable/utils';
import { ReactComponent as ChangeWidth } from 'Assets/icons/changeWidth.svg';
import { ReactComponent as ChangeHeight } from 'Assets/icons/changeHeight.svg';
import { BookleTemplateBlock } from 'types';
import ColorSelectionBox from 'Pages/PageGenerationEditor/GenerationPreview/SettingsModal/ColorSelectionBox/ColorSelectionBox';
import CustomArrowsForSizes from 'Pages/PageGenerationEditor/GenerationPreview/SettingsModal/CustomArrows/CustomArrows';
import SizeStyleControl from 'Components/SizeSelector/SizeStyleControls';
import UrlInput from '../../UrlInput/UrlInput';

import styles from './ButtonStyleTab.module.scss';

const buttonRadiuses = [0, 4, 10];

interface IProps {
  item: BookleTemplateBlock;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const ButtonStyleTab = ({ item, templateBlocks, updateBlocks }: IProps) => {
  const onUpdateStyle = (key: string, value: string | number) => {
    updateItemStyle(key, value, templateBlocks, updateBlocks, item);
  };

  const onUpdateItem = (key: string, value: string) => {
    updateItem(key, value, templateBlocks, updateBlocks, item);
  };

  const changeSize = (
    action: 'increment' | 'decrement',
    key: string,
    value: string | number
  ) => {
    if (action === 'increment' && Number(value) < 1000) {
      onUpdateStyle(key, Number(value) + 1);
    } else {
      if (Number(value) > 0) {
        onUpdateStyle(key, Number(value) - 1);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.urlInput}>
        <UrlInput
          onChange={(value) => onUpdateItem('url', value)}
          value={item.url}
        />
      </div>
      <div className={styles.radiusButtonsContainer}>
        {buttonRadiuses.map((radius) => (
          <div
            className={clsx(styles.radiusButtonBlock, {
              [styles.activeRadiusButton]:
                (item.styles?.borderRadius || 0) === radius,
            })}
            onClick={() => onUpdateStyle('borderRadius', radius)}
          >
            <div
              className={clsx(styles.radiusButton)}
              style={{ borderRadius: radius }}
            />
          </div>
        ))}
      </div>
      <ColorSelectionBox
        label="Button Color"
        value={item.styles?.backgroundColor || '#4957D8'}
        onChange={(color) => onUpdateStyle('backgroundColor', color)}
      />
      <SizeStyleControl
        step={1}
        max={1000}
        min={0}
        value={(item.styles?.height || 50) as string}
        theme="dark"
        displayArrowsOnHover={false}
        customArrows={
          <CustomArrowsForSizes
            className={styles.arrows}
            onIncrement={() =>
              changeSize(
                'increment',
                'height',
                (item.styles?.height as number) || 50
              )
            }
            onDecrement={() =>
              changeSize(
                'decrement',
                'height',
                (item.styles?.height as number) || 50
              )
            }
          />
        }
        title="Height"
        postfix={<span className={styles.px}>px</span>}
        className={styles.sizeFieldWrapper}
        inputClassName={styles.sizeField}
        inputContainerClassName={styles.inputContainerClassName}
        onChange={(value) => {
          onUpdateStyle('height', Number(value));
        }}
        prefix={<ChangeHeight className={styles.inputIcon} />}
      />
      <SizeStyleControl
        step={1}
        displayArrowsOnHover={false}
        max={1000}
        min={0}
        value={(item.styles?.width || 220) as string}
        theme="dark"
        customArrows={
          <CustomArrowsForSizes
            className={styles.arrows}
            onIncrement={() =>
              changeSize(
                'increment',
                'width',
                (item.styles?.width as number) || 220
              )
            }
            onDecrement={() =>
              changeSize(
                'decrement',
                'width',
                (item.styles?.width as number) || 220
              )
            }
          />
        }
        title="Width"
        postfix={<span className={styles.px}>px</span>}
        className={styles.sizeFieldWrapper}
        inputClassName={styles.sizeField}
        inputContainerClassName={styles.inputContainerClassName}
        onChange={(value) => {
          onUpdateStyle('width', Number(value));
        }}
        prefix={<ChangeWidth className={styles.inputIcon} />}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonStyleTab);
