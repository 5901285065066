import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { RootState } from 'store/rootReducer';
import { Descendant } from 'slate';
import { unified } from 'unified';
import markdown from 'remark-parse';
import gfm from 'remark-gfm';
import frontmatter from 'remark-frontmatter';
import { remarkToSlate } from 'remark-slate-transformer';
import { createSocket, getToken } from 'utils/Utils';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
} from 'store/books/booksActions';
import { ReactComponent as SettingsIcon } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as MagicIcon } from 'Assets/icons/magic.svg';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { isAdmin } from 'utils/Utils';
import { Preview2HTML } from '../Draggable/utils';
import { Sidebar, MenuItems } from '../Draggable/Sidebar/Sidebar';
import Button from 'UILib/Button/Button';
import Content from '../Draggable/Content/Content';
import SettingsModal from './SettingsModal/SettingsModal';

import styles from './GenerationPreview.module.scss';

interface IProps {
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  isSidebarHidden?: boolean; // this attribute will hide the sidebar toggle
  onSidebarToggle?: (isSidebarOpen: boolean) => void; // even't which should be handled once toggle sidebar
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;

  generationTask?: string; //subscribing on reciving data for generation;
}

const GenerationPreview = (props: IProps) => {
  const socket = useRef<Socket | null>(null);
  const history = useHistory();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const settingsModalRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  //Handle Reciving Generation Content based on Generation Task ID

  type SocketCallback = (payload: any) => void;

  const setupSocketListeners = (
    socket: Socket | null,
    event: string,
    callback: SocketCallback
  ): (() => void) => {
    if (socket) {
      socket.on(event, callback);
      return () => socket.off(event, callback);
    }
    return () => { };
  };

  useEffect(() => {
    if (!socket.current) {
      socket.current = createSocket();

      if (socket.current) {
        const cleanup = setupSocketListeners(
          socket.current,
          'generating-task-info-response',
          handleGenerationTaskUpdates
        );

        socket.current.on('connect', () => {
          console.log('connected to server');
          if (props.generationTask) {
            subscribeOnGenerationTaskUpdates(props.generationTask);
          }
        });
        return cleanup;
      }
    }

  }, []);

  useEffect(() => {
    const cleanup = setupSocketListeners(
      socket.current,
      'generating-task-info-response',
      handleGenerationTaskUpdates
    );

    return cleanup;
  }, [props.templateBlocks]);

  useEffect(() => {
    if (props.generationTask) {
      subscribeOnGenerationTaskUpdates(props.generationTask);

      //mark all nodes that have variable as pennding for generation
      for (const block of props.templateBlocks) {
        if (block.variable) {
          block.generating = true;
        }
      }
      console.log('updating stuff');
      props.updateBlocks([...props.templateBlocks]);

    }
  }, [props.generationTask]);

  const subscribeOnGenerationTaskUpdates = (taskId: string) => {
    console.log('subscribe:', taskId);
    if (socket.current) {
      socket.current.emit('generating-task-info', {
        taskId,
        token: getToken(),
      });
    }
  };

  const handleGenerationTaskUpdates = (payload: any) => {
    console.log('stuff is coming:', payload);
    if (payload.action === 'content generated') {
      if (payload.data) {
        //TODO: need to fix backend and send error outside data
        if (payload.data.error) {
          console.log('errror: ', payload.data.error);
        } else {
          updateGenerationBlockContent(payload.path, payload.data.result);
        }
      }
    }
  };

  const convertNode = (node: any) => {
    switch (node.type) {
      case 'root':
        return {
          type: 'paragraph',
          children: node.children.map(convertNode),
        };
      case 'paragraph':
        return {
          type: 'paragraph',
          children: node.children.map(convertNode),
        };
      case 'text':
        return {
          text: node.value,
        };
      case 'heading':
        return {
          type: 'title',
          // type: `heading-${node.depth}`,
          depth: node.depth,
          children: node.children.map(convertNode),
        };
      // case "emphasis":
      //   return {
      //     type: "emphasis",
      //     children: node.children.map(convertNode),
      //   };
      case 'break':
        return {
          type: 'break',
          children: [{ text: '\n' }],
        };
      case 'thematicBreak':
        return {
          type: 'line',
          children: [{ text: '' }],
        };
      case 'list':
        return {
          type: 'bulleted-list',
          // ordered: node.ordered,
          children: node.children.map(convertNode),
        };
      case 'listItem':
        return {
          type: 'list-item',
          children: node.children.map(convertNode),
        };
      // case "link":
      //   return {
      //     type: "link",
      //     url: node.url,
      //     children: node.children.map(convertNode),
      //   };
      default:
        const finalNode = node;
        if (node.strong) {
          finalNode.bold = true;
        }
        return finalNode;
    }
  };

  const parseMarkdown = (content: string) => {
    const toSlateProcessor = unified()
      .use(markdown)
      .use(gfm)
      .use(frontmatter)
      .use(remarkToSlate);
    const toSlate = (s: string) => toSlateProcessor.processSync(s).result;
    return toSlate(content).map(convertNode);
  };

  const updateGenerationBlockContent = (path: string, content: string) => {
    const pathComponents = path.split('.');
    const variable = pathComponents[pathComponents.length - 1];

    for (const block of props.templateBlocks) {
      if (block.variable === variable) {
        block.generating = false;
        if (block.type === MenuItems.TEXT_BLOCK) {
          block.text = parseMarkdown(content) as Descendant[];
        } else if (block.type === MenuItems.IMAGE_BLOCK) {
          block.image = content;
        }
        props.updateBlocks([...props.templateBlocks]);
        break;
      }
    }
  };


  /////////////////////////////////////////////

  const handleOpenActions = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('editor', 'true');

    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  UseOnClickOutside(settingsModalRef, (event) => {
    const targetElement = event.target as HTMLElement;
    if (
      isSettingsModalOpen &&
      !Array.from(targetElement.classList).some((className) =>
        className.includes('Dropdown')
      )
    ) {
      handleCloseSettings(event as MouseEvent);
    }
  });

  UseOnClickOutside(sidebarRef, () => {
    if (isSidebarOpen) toggleSidebar();
  });

  useEffect(() => { }, [props.templateBlocks]);

  useEffect(() => {
    if (props.isSidebarHidden) {
      setIsSidebarOpen(false);
    }
  }, [props.isSidebarHidden]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (props.onSidebarToggle) props.onSidebarToggle(!isSidebarOpen);
  };

  const handleCloseSettings = (event: MouseEvent) => {
    if (
      settingsModalRef.current &&
      !settingsModalRef.current.contains(event.target as Node)
    ) {
      setIsSettingsModalOpen(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Button
        className={clsx(styles.addNewFormButton, styles.openSidebarButton, {
          [styles.hiddenSidebar]: props.isSidebarHidden,
        })}
        prefixIcon={<PlusIcon />}
        appearance="solid"
        width={40}
        height={40}
        onClick={toggleSidebar}
      />
      {isSettingsModalOpen ? (
        <div ref={settingsModalRef}>
          <SettingsModal
            styles={props.templateBlockStyles}
            updateStyles={props.updateBlockStyles}
          />
        </div>
      ) : (
        <>
          <Button
            className={clsx(styles.addNewFormButton, styles.settingsButton)}
            prefixIcon={<SettingsIcon className={styles.settingsIcon} />}
            appearance="solid"
            width={40}
            height={40}
            onClick={() => {
              setIsSettingsModalOpen(!isSettingsModalOpen);
            }}
          />

          {isAdmin() && (
            <Button
              className={clsx(
                styles.aiNodesFlowEditorButton,
                styles.settingsButton
              )}
              prefixIcon={<MagicIcon className={styles.settingsIcon} />}
              appearance="solid"
              width={40}
              height={40}
              onClick={handleOpenActions}
            />
          )}
        </>
      )}
      <div className={styles.container}>
        <div
          ref={sidebarRef}
          className={clsx(styles.sidebar, { [styles.closed]: !isSidebarOpen })}
        >
          <Sidebar />
        </div>
        <Content />
      </div>
    </DndProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerationPreview);
