import { connect } from 'react-redux';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import {
  updateItem,
  updateItemStyle,
} from 'Pages/PageGenerationEditor/Draggable/utils';
import CustomArrowsForSizes from 'Pages/PageGenerationEditor/GenerationPreview/SettingsModal/CustomArrows/CustomArrows';
import { ReactComponent as ChangeHeight } from 'Assets/icons/changeHeight.svg';
import { BookleTemplateBlock } from 'types';
import clsx from 'clsx';
import SizeStyleControl from 'Components/SizeSelector/SizeStyleControls';
import ColorSelectionBox from 'Pages/PageGenerationEditor/GenerationPreview/SettingsModal/ColorSelectionBox/ColorSelectionBox';

import styles from './DividerTab.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const DividerTab = ({ item, templateBlocks, updateBlocks }: IProps) => {
  const onUpdateStyle = (key: string, value: string | number) => {
    updateItemStyle(key, value, templateBlocks, updateBlocks, item);
  };

  const onUpdateItem = (key: string, value: boolean) => {
    updateItem(key, value, templateBlocks, updateBlocks, item);
  };

  const changeSize = (
    action: 'increment' | 'decrement',
    key: string,
    value: string | number
  ) => {
    if (action === 'increment' && Number(value) < 1000) {
      onUpdateStyle(key, Number(value) + 1);
    } else {
      if (Number(value) > 0) {
        onUpdateStyle(key, Number(value) - 1);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.spacerContainer}>
        <div
          className={clsx(styles.spacerButton, {
            [styles.activeButton]: item.isSpacer,
          })}
          onClick={() => onUpdateItem('isSpacer', true)}
        >
          Spacer
        </div>
        <div
          className={clsx(styles.spacerButton, {
            [styles.activeButton]: !item.isSpacer,
          })}
          onClick={() => onUpdateItem('isSpacer', false)}
        >
          Divider
        </div>
      </div>
      <SizeStyleControl
        step={1}
        max={1000}
        min={1}
        value={(item.styles?.height || 2) as string}
        theme="dark"
        displayArrowsOnHover={false}
        customArrows={
          <CustomArrowsForSizes
            className={styles.arrows}
            onIncrement={() =>
              changeSize(
                'increment',
                'height',
                (item.styles?.height as number) || 2
              )
            }
            onDecrement={() =>
              changeSize(
                'decrement',
                'height',
                (item.styles?.height as number) || 2
              )
            }
          />
        }
        title="Height"
        postfix={<span className={styles.px}>px</span>}
        className={styles.sizeFieldWrapper}
        inputClassName={styles.sizeField}
        inputContainerClassName={styles.inputContainerClassName}
        onChange={(value) => {
          onUpdateStyle('height', Number(value));
        }}
        prefix={<ChangeHeight className={styles.inputIcon} />}
      />
      {!item.isSpacer && (
        <ColorSelectionBox
          label="Color"
          value={item.styles?.backgroundColor || '#d0d0d0'}
          onChange={(color) => onUpdateStyle('backgroundColor', color)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(DividerTab);
