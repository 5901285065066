import styles from './ProgressBar.module.scss';

interface IProgressBar {
  progress: number;
}

const ProgressBar = ({ progress }: IProgressBar) => {
  return (
    <div className={styles.progressBar}>
      <div
        className={styles.progressPercent}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default ProgressBar;
