import {
  AGENCY_FETCHED,
  LOADING,
  MAILCHIMP_INTEGRATION_SUCCESFULL,
  STRIPE_INTEGRATION_SUCCESFULL,
  USER_ACCESS,
  USER_FETCHED,
  USER_GET_SUBSCRIPTIONS_DATA,
} from './userActions';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  IAgencyDetails,
  IUserAccess,
  IUserDetails,
  IUserSubscriptionPlanData,
} from 'types';

export interface IUserState {
  details: IUserDetails;
  agencyDetails: IAgencyDetails;
  subscriptionPlanData: IUserSubscriptionPlanData;
  isLoading: boolean;
  isMailchimpIntegrationSuccesfull: true | null | false;
  isStripeIntegrationSuccesfull: true | null | false;
  userAccess: IUserAccess | undefined;
}

const initialState: IUserState = {
  details: {
    _id: '',
    email: '',
    stripePublicKey: '',
    stripeSecretKey: '',
    aiCredits: 0,
    customData: {
      mailchimpApiKey: '',
    },
  },
  agencyDetails: {
    _id: '',
    email: '',
    stripePublicKey: '',
    stripeSecretKey: '',
    saasId: null,
    aiCredits: 0,
    customData: {
      mailchimpApiKey: '',
    },
  },
  subscriptionPlanData: {
    price: '',
    planType: '',
    planInterval: '',
  },
  userAccess: undefined,
  isLoading: false,
  isMailchimpIntegrationSuccesfull: null,
  isStripeIntegrationSuccesfull: null,
};

const userReducer = (
  state = initialState,
  action: PayloadAction<
    IUserDetails | IUserSubscriptionPlanData | IUserAccess | boolean
  >
): IUserState => {
  switch (action.type) {
    case USER_FETCHED:
      const userDetails = action.payload as IUserDetails;
      return {
        ...state,
        details: {
          ...state.details,
          ...userDetails,
        },
        isLoading: false,
      };
    case AGENCY_FETCHED:
      const details = action.payload as IAgencyDetails;
      return {
        ...state,
        agencyDetails: {
          ...state.details,
          ...details,
        },
        isLoading: false,
      };
    case USER_ACCESS:
      const userAccess = action.payload as IUserAccess;
      return {
        ...state,
        userAccess,
      };
    case USER_GET_SUBSCRIPTIONS_DATA:
      const subscriptionPlanData = action.payload as IUserSubscriptionPlanData;
      return {
        ...state,
        subscriptionPlanData,
      };
    case LOADING:
      const isLoading = action.payload as boolean;
      return {
        ...state,
        isLoading,
      };
    case MAILCHIMP_INTEGRATION_SUCCESFULL:
      const isMailchimpIntegrationSuccesfull = action.payload as
        | true
        | null
        | false;
      return {
        ...state,
        isMailchimpIntegrationSuccesfull,
      };

    case STRIPE_INTEGRATION_SUCCESFULL:
      const isStripeIntegrationSuccesfull = action.payload as
        | true
        | null
        | false;
      return {
        ...state,
        isStripeIntegrationSuccesfull,
      };
    default:
      return state;
  }
};

export { initialState, userReducer };
