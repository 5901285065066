import React, { useRef, useEffect } from 'react';
import s from './Modal.module.scss';

interface IProps {
  closeModal?: () => void;
  displayModal: boolean;
  children: JSX.Element[] | JSX.Element;
}

const Modal = (props: IProps) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  function closeModal(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (props.closeModal) {
      props.closeModal();
    }
  }

  useEffect(() => {
    if (props.displayModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [props.displayModal]);

  return (
    <div
      className={s.modal}
      onClick={closeModal}
      style={{ display: props.displayModal ? 'block' : 'none' }}
      ref={modalContentRef}
    >
      <div className={s.modalContent} onClick={(e) => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
