import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  deleteAutoSassApp,
  updateAutoSassApp,
} from 'store/autosaas/autosaasActions';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { DispatchType } from 'store/rootReducer';
import { ReactComponent as EditIcon } from 'Assets/icons/edit2.svg';
import { ReactComponent as CloseIcon } from 'Assets/icons/close2.svg';
import { AutoSassIconsOptions } from 'Components/SideMenu/constants';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import Popup from 'UILib/Popup/Popup';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';

import styles from './AutoSassAppsSettingsModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  appData?: IAutoApp;
  deleteAutoSassApp: (id: string) => void;
  updateAutoSassApp: (data: {
    id: string;
    name: string;
    description?: string;
    iconUrl?: string;
  }) => void;
}

const iconsOptions = AutoSassIconsOptions.map((item, index) => ({
  value: item.path,
  label: (
    <div className={styles.option}>
      {item.icon} Icon #{index + 1}
    </div>
  ),
}));

const AutoSassAppsSettingsModal = ({
  open,
  onClose,
  appData,
  deleteAutoSassApp,
  updateAutoSassApp,
}: IProps) => {
  const [selectedIcon, setSelectedIcon] = useState<string>('');
  const [appName, setAppName] = useState<string>('');
  const [appDescription, setAppDescription] = useState<string>('');
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    if (appData) {
      setAppDescription(appData?.description || '');
      setAppName(appData.name);
      setSelectedIcon(appData.iconUrl || '');
    }
  }, [appData]);

  if (!open) {
    return null;
  }

  const handleUpdate = () => {
    updateAutoSassApp({
      id: appData?._id || '',
      name: appName,
      iconUrl: selectedIcon,
      description: appDescription,
    });
    handleClose();
  };

  const handleDelete = () => {
    deleteAutoSassApp(appData?._id || '');
    setIsDeletePopupOpen(false);
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setSelectedIcon('');
    setAppName('');
    setAppDescription('');
  };

  const onUpdateTemplate = () => {
    history.push(
      `/console/generationTemplate/${appData?.template?._id}/edit?app=${appData?._id}&editor=true`
    );
  };

  return (
    <>
      <Popup onClose={handleClose} wrapperClassName={styles.popup}>
        <div className={styles.container}>
          <div className={styles.title}>App Settings</div>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabel}>Name</div>
            <div className={styles.nameInputContainer}>
              <Input
                className={styles.nameInput}
                border="stroke"
                value={appName}
                onChange={(e) => setAppName(e.target.value)}
              />
              <Button
                prefixIcon={<EditIcon />}
                className={styles.editButton}
                onClick={onUpdateTemplate}
              >
                Edit Mind Map
              </Button>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabel}>Description</div>
            <TextArea
              onChange={(e) => setAppDescription(e)}
              className={styles.textArea}
              value={appDescription}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputLabel}>App Icon</div>
            <Dropdown
              options={iconsOptions}
              label={
                iconsOptions.find((item) => item.value === selectedIcon)
                  ?.label || iconsOptions[17].label
              }
              type="stroke"
              onChange={(value) => setSelectedIcon(value)}
              optionsClassName={styles.optionsClassName}
            />
          </div>
          <div
            className={styles.deleteContainer}
            onClick={() => setIsDeletePopupOpen(true)}
          >
            Delete App <CloseIcon />
          </div>
          <div className={styles.buttonContainer}>
            <Button appearance="stroke" onClick={handleClose}>
              Cancel Changes
            </Button>
            <Button onClick={handleUpdate} disabled={!appName}>
              Save
            </Button>
          </div>
        </div>
      </Popup>
      <ConfirmArchivationPopup
        description="Are you sure you want to delete this app? 
          It will not be available to you and all data will be lost."
        onClose={() => setIsDeletePopupOpen(false)}
        open={isDeletePopupOpen}
        onDonePressed={handleDelete}
      />
    </>
  );
};
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  deleteAutoSassApp: (id: string) => dispatch(deleteAutoSassApp(id)),
  updateAutoSassApp: (data: {
    id: string;
    name: string;
    description?: string;
    iconUrl?: string;
  }) => dispatch(updateAutoSassApp(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoSassAppsSettingsModal);
