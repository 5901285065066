import { useEffect, useRef, useState } from 'react';
import { api, getToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';
import { subdomainBlackList } from './blackList';
import Button from 'UILib/Button/Button';
import Textarea from '../../../Common/Textarea/Textarea';
import InputV1 from '../../../Common/Inputs/InputV1/InputV1';
import InputSubdomain from '../../../Common/Inputs/InputSubdomain/InputSubdomain2';

import s from './GeneralTab.module.scss';

interface IProps {
  name: string;
  setName: (name: string) => void;
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (text: string) => void;
  handleSave: (newName?: string) => void;
  customSubDomain: string;
  setSubDomain: (text: string) => void;
  onClose: () => void;
  setProjectNameAction: (id: string, newName: string) => void;
  id: string;
  favicon?: string;
  onFaviconChange: (icon: string) => void;
}

const GeneralTab = (props: IProps) => {
  const [nameInput, setNameInput] = useState('');
  const [customSubDomainError, setCustomSubDomainError] = useState<
    null | string
  >(null);
  const uploadFaviconInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  useEffect(() => {
    setNameInput(props.name);
  }, [props.name]);

  const handleSaveRequiredField = (e: any) => {
    if (e.target.value.trim()) {
      props.setName(nameInput);
      props.setProjectNameAction(props.id, nameInput);
      props.handleSave(nameInput);
    }
  };

  const handleSaveAndClose = async () => {
    if (customSubDomainError) {
      return;
    }
    if (nameInput.trim()) {
      props.setName(nameInput);
      props.setProjectNameAction(props.id, nameInput);
      props.handleSave(nameInput);
      if (uploadedFile) {
        await handleUpdateFunnelLogo(uploadedFile);
        setUploadedFile(null);
      }
      props.onClose();
    }
  };

  const validateCustomSubDomain = (value: string) => {
    const regex = /^(\w|\d|-)*$/gi;
    setCustomSubDomainError(null);

    let badWordFound = false;
    for (const badWord of subdomainBlackList) {
      if (value.toLowerCase().includes(badWord.toLowerCase())) {
        badWordFound = true;
      }
    }

    if (badWordFound) {
      return false;
    }

    return regex.test(value as string);
  };

  const handleSetSubDomain = (subDomain: string) => {
    const domainValidated = validateCustomSubDomain(subDomain);
    if (!domainValidated) {
      setCustomSubDomainError('Please use a valid subdomain.');
    }
    props.setSubDomain(subDomain);
  };

  const handleSave = () => {
    if (customSubDomainError) {
      return;
    }
    props.handleSave();
  };

  const handleUpdateFunnelLogo = async (image: File) => {
    const request = new FormData();
    request.append('file', image);
    request.append('isFavicon', 'true');
    request.append('id', props.id);

    const headers = {
      authorization: getToken(),
    };
    await api(
      `${PAGECRAFT_API_URL}/funnel/upload-image`,
      'POST',
      request,
      headers
    );
  };

  const handleFileUpload = async () => {
    if (!uploadFaviconInputRef?.current) {
      return;
    }
    const files = uploadFaviconInputRef.current.files;
    if (!files?.length) {
      return;
    }
    let file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (res) => {
      const content = reader.result;
      const imageSrc = String(content);
      props.onFaviconChange(imageSrc);
      setUploadedFile(file);
    };
  };

  return (
    <div className={s.container}>
      <div className={s.faviconUpload}>
        <div className={s.faviconUploadTitle}>
          {props.favicon ? (
            <img src={props.favicon} alt="favicon" />
          ) : (
            <div className={s.faviconPlaceholder} />
          )}
          Favicon
        </div>
        <div className={s.uploadButtonWrapper}>
          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
            ref={uploadFaviconInputRef}
            onChange={handleFileUpload}
          />
          <Button
            width={158}
            height={40}
            appearance="stroke"
            onClick={() => uploadFaviconInputRef.current?.click()}
          >
            Upload Image
          </Button>
          <div className={s.infoText}>
            Formats: ICO/PNG/JPEG; Recommended size: 128x128 px.
          </div>
        </div>
      </div>
      <div className={s.input}>
        <InputV1
          width={760}
          label={'Project Name'}
          placeholder={'My Project'}
          value={nameInput}
          setState={setNameInput}
          type={'text'}
          onBlur={(e: any) => handleSaveRequiredField(e)}
        />
      </div>
      <div className={s.input}>
        <InputV1
          width={760}
          label="Tab Title"
          placeholder={'My Title'}
          value={props.title ?? ''}
          setState={props.setTitle}
          type={'text'}
          onBlur={() => props.handleSave()}
        />
      </div>
      <div className={s.textArea}>
        <Textarea
          width={760}
          label={'Project Description'}
          placeholder={'My Description'}
          value={props.description ?? ''}
          setState={props.setDescription}
          onBlur={() => props.handleSave()}
        />
      </div>
      {props.customSubDomain && (
        <div className={s.input}>
          <InputSubdomain
            name="customSubdomain"
            label="Custom subdomain"
            width={760}
            placeholder={'mysubdomain'}
            value={props.customSubDomain ?? ''}
            setState={handleSetSubDomain}
            onBlur={() => handleSave()}
            error={customSubDomainError}
            readonly
          />
        </div>
      )}
      <div className={s.buttonBlock}>
        <Button
          appearance="solid"
          width={158}
          height={50}
          onClick={handleSaveAndClose}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default GeneralTab;
